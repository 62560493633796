<template>
    <section class="bg-blue-light text-center pad-y-60">
      <div class="container">
        <div class="heading" data-aos="fade-down">
          <h2 class="font-secondary text-teal-dark title" style="font-size: 20px">بِسْمِ اللّٰهِ الرَّحْمٰنِ الرَّحِيْمِ</h2>
          <div class="mt-4 sub-title">Assalamu`alaikumWarahmatullaahi Wabarakaatuh</div>
        </div>

        <p class="font-weight-lighter font-italic mar-bottom-25" data-aos="fade-right" data-aos-delay="300">
          Dengan memohon Rahmat dan Ridho Allah SWT Yang telah menciptakan makhluk-nya berpasangan-pasangan, Kami bermaksud menyelenggarakan pernikahan kedua putra dan putri kami pada
        </p>

        <div class="row">
          <div class="text-center col-md-4 mar-bottom-25" data-aos="fade-right" data-aos-delay="500">
            <div>
              <img src="@/assets/icons/calendar.svg">
              <div class="font-weight-lighter mt-3 text-uppercase text-muted">
                HARI / TANGGAL
              </div>
              <div class="font-weight-bold fs-18">{{ $parent.time.start.format('dddd, DD MMMM YYYY') }}</div>
            </div>
          </div>
          <div class="text-center col-md-4 mar-bottom-25" data-aos="fade-down" data-aos-delay="500">
            <div>
              <img src="@/assets/icons/clock.svg">
              <div class="font-weight-lighter mt-3 text-uppercase text-muted">
                WAKTU
              </div>
              <div class="font-weight-bold fs-18">{{ $parent.time.start.format('HH.mm') }} - {{ $parent.time.end }}</div>
            </div>
          </div>
          <div class="text-center col-md-4 mar-bottom-25" data-aos="fade-left" data-aos-delay="500">
            <div>
              <img src="@/assets/icons/marker.svg">
              <div class="font-weight-lighter mt-3 text-uppercase text-muted">
                Lokasi
              </div>
              <div class="font-weight-bold fs-18" v-html="$parent.location"></div>
            </div>
          </div>
        </div>

        <p class="font-weight-lighter font-italic" data-aos="fade-left" data-aos-delay="600">
          Merupakan suatu kehormatan dan kebahagiaan bagi kami apabila Bapak/Ibu/Saudara/i berkenan hadir dan memberikan doa restu kepada putra-putri kami. Atas kehadiran serta doa restu Bapak/Ibu/Saudara/i, kami sekeluarga mengucapkan terima kasih. Kami yang berbahagia.
        </p>

        <h3 class="font-secondary text-teal-dark mb-0 mar-top-25" data-aos="fade-up" data-aos-delay="700">{{ $parent.wedding.man.surname }} & {{ $parent.wedding.woman.surname }}</h3>
      </div>
    </section>
</template>

<script>
export default {

}
</script>